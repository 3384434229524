.main {
  &:global(.has-admin-bar) {
    @apply pt-12 sm:pt-12 md:pt-9;

    &:global(.has-top-menu) {
      @apply md:pt-18;
    }
  }

  &:global(.has-top-menu) {
    @apply md:pt-10;
  }

  &:global(.has-green-bg) {
    @apply bg-good-green-10;
  }
}
