.wrapper {
  @apply flex flex-col justify-center items-center text-center bg-good-green-20;

  & h1 {
    @apply text-3xl font-bold mb-4;
  }

  & p {
    @apply text-lg max-w-1xl mb-4;
  }

  & span {
    @apply font-mono;
  }

  & :global(.logo) {
    @apply w-20 h-auto mb-4;
  }
}
